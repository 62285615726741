import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
const Wrapper = styled.div`
  /* padding: 0% 5%; */
  font-size: 85%;
  font-family: 'HKGroteskRegular';

  display: grid;
  @media (min-width: 600px) {
    /* padding: 10% 5%; */
    font-size: 100%;

    grid-template-columns: 0.45fr 0.55fr;
    grid-gap: 90px;
  }

  a {
    color: rgb(70, 103, 249);
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 32x;
    border-bottom: 2px solid rgb(70, 103, 249);
    &:visited {
      color: rgb(70, 103, 249);
    }
    &:hover,
    &:active {
      border-bottom: none;
      background: black;
      color: white;
    }
  }
`

const Left = styled.div`
  color: black;
  max-width: 550px;
  padding: 10% 0 5% 10%;
  display: grid;

  span {
    font-family: 'HKGroteskBold';
    font-weight: 800;
    font-size: 1.5em;
  }

  h1 {
    color: black;
    font-family: 'HKGroteskBold';
    font-weight: 800;
    font-size: 3.5em;
  }
  h2 {
    color: #666;
    font-size: 120%;
    line-height: 165%;
    font-weight: 500;
  }
`
const Right = styled.div`
  p {
    font-size: 0.8em;
  }
  color: #666;
  font-size: 130%;
  line-height: 150%;
  height: 100vh;
  width: 100%;
  background: url('https://images.unsplash.com/photo-1506020757198-1a3adb04b6b5?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a5c7bc072409667341e6ba503f82846a&auto=format&fit=crop&h=2000&q=80');
  background-size: cover;
`
const Projects = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 40px 0;
  justify-items: start;
  grid-template-columns: repeat(3, 1fr);

  font-size: 1em;
  font-weight: 900;
  text-align: center;

  span {
    font-size: 4em;
  }
`

const IndexPage = () => (
  <Layout>
    <Wrapper>
      <Left>
        <span>Chase Olivieri</span>
        <h1>Product focused global nomad.</h1>
        <h2>
          I’m a software engineer living in Northern California. Travel is my
          passion. You can follow me on{' '}
          <a href="https://twitter.com/chaseolivieri" target="_blank">
            Twitter
          </a>{' '}
          or see my photos on{' '}
          <a href="https://unsplash.com/@chasefoto" target="_blank">
            Unsplash
          </a>
          . I occasionally{' '}
          <a href="https://usealtitude.com/posts" target="_blank">
            blog
          </a>{' '}
          too.
        </h2>
        <h2>
          I recently started a small product studio taking on design, product,
          and UI/UX work. If you’d like to work together, please{' '}
          <a href="mailto:hi@cpo.is" target="_blank">
            get in touch
          </a>
          .
        </h2>
      </Left>
      <Right />
    </Wrapper>
  </Layout>
)

export default IndexPage
